<template>
  <div class="header__nav header__item h-align-right">
    <ul class="menu h-nowrap">
      <li v-for="item in navigationItems" :key="item.name" class="menu__item">
        <nuxt-link
          v-smooth-scroll
          :to="`/${item.url}`"
          class="menu__link"
          :target="item.newTab === true ? '_blank' : '_self'"
          @click="navigationClicked"
        >
          {{ item.name }}
        </nuxt-link>
        <nuxt-link
          v-smooth-scroll
          :to="`/#contact`"
          class="button"
          :target="_self"
          @click="$gtag.event('cta', 'click', 'Contact Us Header')"
        >
          <span class="button__inner">Contact Us</span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HeaderNavigation',
  emits: ['navigation-clicked'],
  data() {
    return {
      navigationItems: [
        {
          name: 'Results',
          url: '#portfolio',
          newTab: false,
        },
      ],
    }
  },
  methods: {
    navigationClicked() {
      this.$emit('navigation-clicked')
    },
  },
}
</script>
