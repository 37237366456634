<template>
  <section class="section testimonials">
    <div class="container">
      <div class="columns">
        <div class="column is-ten is-offset-one">
          <blockquote class="testimonial">
            <h2
              class="testimonial__author heading -color-white h-margin-bottom-0"
            >
              Mihu Cantemir
            </h2>
            <h3 class="testimonial__title">Head of Business Unit, NTT Data</h3>
            <h2 class="testimonial__text h1 heading -font-weight-200">
              <span class="testimonial__icon" />
              They are highly focused on quality;
              <em>Their code was well written and very well documented</em>.
            </h2>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Testimonial',
}
</script>
