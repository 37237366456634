<template>
  <div class="partners-wrapper">
    <div class="partners-intro">
      <h2 class="heading">
        We are trusted partners to some of the
        <br />
        <strong>companies that move the world</strong>
      </h2>
    </div>
    <section class="partners">
      <partner
        v-for="(partner, index) in partners"
        :key="index"
        :text="partner.text"
        :alt="partner.alt"
        :image="partner.image"
        :width="partner.width"
        :height="partner.height"
      />
    </section>
  </div>
</template>

<script>
import Partner from './Partner'

export default {
  name: 'Partners',
  components: {
    partner: Partner,
  },
  data() {
    return {
      partners: [
        {
          alt: 'Logo of The United Nations',
          text: 'The United Nations',
          image: require('~/assets/images/partners/united-nations.svg'),
          width: 104,
          height: 50,
        },
        {
          alt: 'Logo of NTT Data - Global software outsourcing',
          text: 'Global software outsourcing partner',
          image: require('~/assets/images/partners/ntt-data.svg'),
          width: 104,
          height: 50,
        },
        {
          alt: 'Logo of Flyeralarm - Industy leading print services',
          text: 'Industy leading print services',
          image: require('~/assets/images/partners/flyeralarm.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Virgin Media - Global telecomunication services',
          text: 'Global telecomunication services',
          image: require('~/assets/images/partners/virgin-media.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Luxgive - Novel travel startup',
          text: 'Novel travel startup',
          image: require('~/assets/images/partners/luxgive.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Wolters Kluvers - The all-in-one law firm software for automated file flows.',
          text: 'All-in-one law firm software for automated file flows',
          image: require('~/assets/images/partners/wolters-kluvers.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Trafalgar - Luxury villa and condo rentals',
          text: 'Luxury villa and condo rentals',
          image: require('~/assets/images/partners/st-martin.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of VSP - Global software outsourcing partner',
          text: 'Global software outsourcing partner',
          image: require('~/assets/images/partners/vsp.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Hochhuth - Energy management solutions',
          text: 'Energy management solutions',
          image: require('~/assets/images/partners/hochhuth.svg'),
          width: 102,
          height: 50,
        },
        {
          alt: 'Logo of xSafe - Digital assets management',
          text: 'Digital assets management on MultiversX',
          image: require('~/assets/images/partners/xsafe.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: 'Logo of Legion Ventures - The mind behind the Happy Planet Index',
          text: 'Community driven, early-stage venture capital',
          image: require('~/assets/images/partners/legion.svg'),
          width: 100,
          height: 50,
        },
        {
          alt: "Logo of County Supplies - London's top fruit & vegetable wholesaler",
          text: "London's top fruit & vegetable wholesaler",
          image: require('~/assets/images/partners/county-supplies.svg'),
          width: 100,
          height: 50,
        },
      ],
    }
  },
}
</script>
