<template>
  <div class="hero__animation">
    <img
      src="~/assets/images/logo-animated.svg"
      alt="graffino"
      class="hero__animation-svg"
      data-not-lazy="true"
    />
    <div ref="heroAnimation" class="hero__animation-wrapper" />
    <h1 :class="{ 'is-active': isActive }" class="hero__headline">
      Software Architects
    </h1>
    <div class="hero__button">
      <div class="button--ghost" @click="replayAnimation">
        <svg class="icon replay__icon" width="18" height="18">
          <use xlink:href="#icon-replay" />
        </svg>
        <span class="button__inner">
          <span class="h-hide-tablet-down"> Replay </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import lottieLight from 'lottie-web/build/player/lottie_light'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  name: 'HeroAnimation',
  mixins: [UtilityMixin],
  data() {
    return {
      logoAnimation: null,
      isActive: false,
    }
  },
  mounted() {
    this.playAnimation()
  },
  methods: {
    show() {
      this.isActive = true
    },
    playAnimation() {
      this.logoAnimation = lottieLight.loadAnimation({
        container: this.findRef(this.$refs.heroAnimation),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        progressiveLoad: true,
        path: 'media/logo-animation.json',
      })
      setTimeout(() => {
        this.logoAnimation.play()
        if (this.logoAnimation.wrapper.children[0]) {
          this.logoAnimation.wrapper.children[0].style.height = '30vh'
        }
        this.show()
      }, 900)
    },
    replayAnimation() {
      setTimeout(() => {
        this.logoAnimation.goToAndPlay(0)
      }, 300)
    },
  },
}
</script>
