var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"book-appointment__contact"},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('form',{staticClass:"form -book-appointment",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('input',{ref:"antispam",staticClass:"h-visually-hidden",attrs:{"type":"text","value":"","name":"antispam"}}),_vm._v(" "),_c('div',{staticClass:"form__inline"},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.first_name),expression:"formData.first_name"}],staticClass:"form__input -background-transparent -color-input",class:{
                '-error-field': errors.length > 0,
                '-border-thinner': !errors.length > 0,
              },attrs:{"id":"first-name","placeholder":"First Name:"},domProps:{"value":(_vm.formData.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "first_name", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"form__label",class:errors.length > 0 ? 'has-error' : ''},[_vm._v("First Name")]),_vm._v(" "),(errors.length > 0)?_c('p',{staticClass:"form__error"},[_vm._v("\n              "+_vm._s(errors[0])+"\n            ")]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.last_name),expression:"formData.last_name"}],staticClass:"form__input -background-transparent -color-input",class:{
                '-error-field': errors.length > 0,
                '-border-thinner': !errors.length > 0,
              },attrs:{"id":"last-name","placeholder":"Last Name:"},domProps:{"value":(_vm.formData.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "last_name", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"form__label",class:errors.length > 0 ? 'has-error' : ''},[_vm._v("Last Name")]),_vm._v(" "),(errors.length > 0)?_c('p',{staticClass:"form__error"},[_vm._v("\n              "+_vm._s(errors[0])+"\n            ")]):_vm._e()])]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form__input -background-transparent -color-input",class:{
              '-error-field': errors.length > 0,
              '-border-thinner': !errors.length > 0,
            },attrs:{"id":"email","placeholder":"Email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"form__label",class:errors.length > 0 ? 'has-error' : ''},[_vm._v("Email")]),_vm._v(" "),(errors.length > 0)?_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field"},[_c('vue-tel-input',{staticClass:"form__input -background-transparent -color-input",class:{
              '-error-field': errors.length > 0 && _vm.touched,
              '-border-thinner': !(errors.length > 0 && _vm.touched),
            },attrs:{"id":"phone","placeholder":"Phone:"},on:{"focus":function($event){_vm.touched = true},"country-changed":_vm.countryChanged},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_vm._v(" "),(errors.length > 0 && _vm.touched)?_c('p',{staticClass:"form__error"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field -textarea-margin"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],staticClass:"form__textarea -background-transparent -color-input",class:{
              '-error-field': errors.length > 0,
              '-border-thinner': !errors.length > 0,
            },attrs:{"id":"message","placeholder":"Please provide more context or details about the project and specify your preferred time slots for phone calls, as we will reach out to you via telephone."},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "message", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('p',{staticClass:"form__error"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"form__field -last-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.budget),expression:"formData.budget"}],staticClass:"form__input -background-transparent -color-input",class:{
              '-error-field': errors.length > 0,
              '-border-thinner': !errors.length > 0,
            },attrs:{"id":"Budget","placeholder":"Budget(EURO):","type":"number"},domProps:{"value":(_vm.formData.budget)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "budget", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"form__label",class:errors.length > 0 ? 'has-error' : ''},[_vm._v("Budget (EURO):")]),_vm._v(" "),(errors.length > 0)?_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_vm._v(" "),(_vm.requestError)?_c('p',{staticClass:"form__error-message"},[_vm._v("\n        There was an error submitting your request. Please try again later.\n      ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"button",class:{
          'is-disabled': invalid === true,
          'is-loading': _vm.loading === true,
        },attrs:{"type":"submit","name":"submit","disabled":invalid},on:{"click":function($event){return _vm.$gtag.event('cta', 'click', 'Contact form homepage')}}},[(!_vm.loading)?_c('span',{staticClass:"button__inner"},[_vm._v("Get in touch")]):_c('div',{staticClass:"spinner button__inner"},[_c('LoadingSpinner')],1)])],1)]}}])}),_vm._v(" "),(_vm.requestError && _vm.sending)?_c('p',{staticClass:"form__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }