<template>
  <App>
    <Hero />
    <main class="main">
      <Header />
      <Mission />
      <Services />
      <Banner />
      <Network />
      <Portfolio />
      <Testimonial />
      <Booking />
    </main>
    <Footer />
    <client-only>
      <cookie-law ref="cookie" transition-name="fade">
        <div class="Cookie__inner">
          <h3 class="Cookie__title">We got a cookie for you!</h3>
          <p class="Cookie__text">
            We use <a class="link" href="/privacy-policy">cookies</a> to provide
            you the best <br />
            experience on our website.
          </p>
          <div class="Cookie__actions">
            Scroll down to accept our
            <a class="link h-display-tablet-down" href="/privacy-policy"
              >cookies</a
            >
          </div>
        </div>
      </cookie-law>
    </client-only>
  </App>
</template>

<script>
import App from '~/components/App.vue'
import Footer from '~/components/Footer.vue'
import Booking from '~/components/Booking.vue'
import Testimonial from '~/components/Testimonial'
import Portfolio from '~/components/Portfolio'
import Network from '~/components/Network'
import Banner from '~/components/Banner'
import Services from '~/components/Services'
import Mission from '~/components/Mission'
import Hero from '~/components/Hero'
import Header from '~/components/Header'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  components: {
    App,
    Footer,
    Booking,
    Testimonial,
    Portfolio,
    Network,
    Banner,
    Services,
    Mission,
    Hero,
    Header,
  },
  mixins: [UtilityMixin],
  data() {
    return {
      scrollTop: 0,
      delta: 300,
    }
  },
  head: {
    bodyAttrs: {
      class: 'page-home',
    },
  },
  mounted() {
    const _this = this
    this.$nextTick(() => {
      window.addEventListener('scroll', _this.acceptCookies)
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  unmounted() {
    window.removeEventListener('scroll', this.acceptCookies)
  },
  methods: {
    acceptCookies() {
      if (this.scrollTop > this.delta) {
        this.findRef(this.$refs.cookie).accept()
        window.removeEventListener('scroll', this.acceptCookies)
      }
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop
    },
  },
}
</script>
