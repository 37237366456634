<template>
  <header id="header" :class="{ 'is-active': isActive }" class="header">
    <div class="header__wrapper">
      <div ref="headerInside" class="header__inside">
        <div class="header__nav header__item h-align-left">
          <ul v-if="windowWidth > 480" class="menu h-nowrap">
            <li class="menu__item">
              <a
                v-smooth-scroll="{ offset: 1 }"
                href="#header"
                class="menu__link"
                @click.prevent="
                  toggleCategories()
                  closeMenu()
                  closeSearch()
                "
                >{{ categoriesToggle() ? 'Hide ' : 'Services' }}</a
              >
            </li>
            <li
              v-for="item in navigationItems"
              :key="item.name"
              class="menu__item"
            >
              <nuxt-link
                v-smooth-scroll
                :to="`/${item.url}`"
                class="menu__link"
                :target="item.newTab === true ? '_blank' : '_self'"
                @click="navigationClicked"
              >
                {{ item.name }}
              </nuxt-link>
            </li>
          </ul>
          <div
            v-else
            class="header__hamburger header__item h-align-left"
            @click="handleMobileNavigation"
          >
            <a
              class="hamburger"
              :class="{ 'is-active': isMobileMenuOpen }"
              aria-label="Hamburger Menu"
            >
              <div class="hamburger__slice" />
              <div class="hamburger__slice" />
            </a>
          </div>
        </div>
        <div class="header__item h-align-center">
          <transition name="fade">
            <a
              v-if="!isActive"
              v-smooth-scroll="{ offset: 1 }"
              href="#mission"
              class="header__scroll-icon"
              aria-label="Scroll down to the next section"
            >
              <svg class="icon" width="19px" height="28px">
                <use xlink:href="#icon-mouse" />
              </svg>
              <svg class="icon" width="7px" height="10px">
                <use xlink:href="#icon-arrow-down" />
              </svg>
            </a>
          </transition>
          <transition name="fade">
            <nuxt-link
              v-if="isActive"
              v-smooth-scroll
              class="header__logo"
              to="/#hero"
              @click="showOverlay ? (showOverlay = !showOverlay) : null"
            >
              <img
                :src="require('~/assets/images/logo-white.svg')"
                width="150"
                height="50"
                alt="Logo of Graffino"
                data-not-lazy="true"
              />
            </nuxt-link>
          </transition>
        </div>
        <header-navigation
          @navigation-clicked="
            showOverlay ? (showOverlay = !showOverlay) : null
          "
        />
      </div>
      <transition name="fade">
        <div v-if="isMobileMenuOpen" class="header__mobile">
          <ul class="menu h-nowrap">
            <li class="menu__item">
              <a
                v-smooth-scroll="{ offset: 1 }"
                href="#header"
                class="menu__link"
                @click.prevent="
                  toggleCategories()
                  closeMenu()
                  closeSearch()
                "
                >{{ categoriesToggle() ? 'Hide ' : 'Services' }}</a
              >
            </li>
            <li
              v-for="item in navigationItems"
              :key="item.name"
              class="menu__item"
            >
              <nuxt-link
                v-smooth-scroll
                :to="`/${item.url}`"
                class="menu__link"
                :target="item.newTab === true ? '_blank' : '_self'"
                @click="navigationClicked"
              >
                {{ item.name }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <header-overlay :show-overlay="showOverlay" />
    <categories-overlay :show-overlay="showCategoriesOverlay()" />
  </header>
</template>

<script>
import HeaderOverlay from './HeaderOverlay'
import HeaderNavigation from './HeaderNavigation'
import UtilityMixin from '../mixins/UtilityMixin.vue'
import CategoriesOverlay from './CategoriesOverlay'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Header',
  components: {
    'header-overlay': HeaderOverlay,
    'header-navigation': HeaderNavigation,
    'categories-overlay': CategoriesOverlay,
  },
  mixins: [UtilityMixin],
  emits: ['navigation-clicked'],
  data() {
    return {
      ...mapGetters({
        showOverlay: 'overlay/isVisible',
        showCategoriesOverlay: 'overlay/categoriesToggle',
        searchToggle: 'overlay/searchToggle',
        categoriesToggle: 'overlay/categoriesToggle',
      }),
      isActive: false,
      showOverlay: false,
      lastScrollTop: 0,
      scrolled: false,
      delta: 125,
      windowWidth: 0,
      isMobileMenuOpen: false,
      navigationItems: [
        {
          name: 'Careers',
          url: 'careers',
          newTab: true,
        },
      ],
    }
  },
  computed: {
    getTopPosition() {
      return (
        this.findRef(this.$refs.headerInside).getBoundingClientRect().top +
        window.scrollY
      )
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleMobileNavigation() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
      this.categoriesToggle() ? this.closeCategories() : ''
    },
    handleScroll() {
      this.scrolled = window.scrollY
      if (this.scrolled > this.getTopPosition) {
        if (this.scrolled > this.delta) {
          this.isActive = true
        } else {
          this.isActive = false
        }
        this.lastScrollTop = this.scrolled
      } else {
        this.isActive = false
      }
    },
    navigationClicked() {
      this.$emit('navigation-clicked')
    },
    ...mapMutations({
      toggleSearch: 'overlay/toggleSearch',
      toggleCategories: 'overlay/toggleCategories',
      closeMenu: 'overlay/hide',
      closeCategories: 'overlay/closeCategories',
      closeSearch: 'overlay/closeSearch',
    }),
  },
}
</script>
