<template>
  <div id="contact" class="book-appointment">
    <div class="book-appointment__background">
      <mac-window class="booking">
        <div class="book-appointment__content">
          <div class="book-appointment__wrapper">
            <div class="book-appointment__container">
              <div class="book-appointment__device">
                <svg class="footer__icon" width="110" height="110">
                  <use xlink:href="#icon-combined-shape" />
                </svg>
              </div>
              <p class="book-appointment__message">
                book an appointment with us and <br />
                <strong>let us help you execute on your product vision.</strong>
              </p>
              <div class="book-appointment__email">
                <strong class="book-appointment__mention-email"
                  >Or email us at</strong
                >
                <a
                  class="link"
                  href="mailto:hello@graffino.com"
                  @click="$gtag.event('contact', 'Email Contact')"
                >
                  <strong>hello@graffino.com</strong>
                </a>
              </div>
            </div>
          </div>
          <div class="book-appointment__wrapper">
            <BookForm />
          </div>
        </div>
      </mac-window>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import BookForm from './BookForm.vue'

export default {
  name: 'BookAppointment',
  components: {
    BookForm,
  },
  methods: {
    ...mapMutations({
      openModal: 'popup/openModal',
    }),
  },
}
</script>
