<template>
  <div class="hero__latest-article">
    <a
      class="latest-article link"
      href="/careers"
      target="_blank"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div
        ref="latestNewsAnimation"
        width="58"
        height="65"
        class="latest-article__icon"
      />
      <h3 class="latest-article__title">
        We're hiring! Become part of our team!
      </h3>
    </a>
  </div>
</template>

<script>
import lottieLight from 'lottie-web/build/player/lottie_light'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  name: 'LatestNews',
  mixins: [UtilityMixin],
  data() {
    return {
      iconAnimation: null,
      hover: false,
    }
  },
  watch: {
    hover: function (val) {
      if (val) {
        setTimeout(() => {
          this.iconAnimation.goToAndPlay(0)
        }, 300)
      }
    },
  },
  mounted() {
    this.loadAnimation()
  },
  methods: {
    loadAnimation() {
      this.iconAnimation = lottieLight.loadAnimation({
        container: this.findRef(this.$refs.latestNewsAnimation),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        progressiveLoad: true,
        path: 'media/linkedin-animated-icon.json',
      })
      this.iconAnimation.play()
    },
  },
}
</script>
