<template>
  <div :class="type" class="portfolio__item">
    <div class="portfolio__item-image">
      <LazyImgWrapper
        :image-path="`portfolio/${imageName}`"
        :image-alt="name"
        sizes="(max-width:640px)
      50vw, (max-width:940px) 33vw, (max-width:1200px) 22vw, 11vw"
        :not-lazy="true"
      />
    </div>
    <div class="portfolio__item-copy">
      <div class="portfolio__item-inner">
        <h2 class="portfolio__item-copy__title">
          {{ name }}
        </h2>
        <hr class="divider" />
        <h4 class="portfolio__item-copy__subtitle">
          {{ description }}
        </h4>
        <span class="portfolio__item-copy__text">{{ text }}</span>

        <a :href="url" class="button portfolio__button">
          <span class="button__inner">
            <span>{{ buttonText }}</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortfolioItem',
  props: {
    name: {
      type: String,
      default: 'Awesome Case Study',
    },
    imageName: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Discover',
    },
    url: {
      type: String,
      default: '/case-studies',
    },
    description: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
}
</script>
