<template>
  <div>
    <section class="book-appointment__distance">
      <book-appointment />
    </section>
    <section>
      <div>
        <div class="columns">
          <div class="column is-twelve" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BookAppointment from './BookAppointment.vue'

export default {
  name: 'Booking',
  components: {
    BookAppointment,
  },
}
</script>
