<template>
  <div class="book-appointment__contact">
    <ValidationObserver
      ref="validationObserver"
      v-slot="{ handleSubmit, invalid }"
    >
      <form
        class="form -book-appointment"
        @submit.prevent="handleSubmit(submitForm)"
      >
        <input
          ref="antispam"
          type="text"
          value=""
          name="antispam"
          class="h-visually-hidden"
        />
        <div class="form__inline">
          <ValidationProvider v-slot="{ errors }" rules="required|alpha_spaces">
            <div class="form__field">
              <input
                id="first-name"
                v-model="formData.first_name"
                :class="{
                  '-error-field': errors.length > 0,
                  '-border-thinner': !errors.length > 0,
                }"
                class="form__input -background-transparent -color-input"
                placeholder="First Name:"
              />
              <label
                :class="errors.length > 0 ? 'has-error' : ''"
                class="form__label"
                >First Name</label
              >
              <p v-if="errors.length > 0" class="form__error">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required|alpha_spaces">
            <div class="form__field">
              <input
                id="last-name"
                v-model="formData.last_name"
                :class="{
                  '-error-field': errors.length > 0,
                  '-border-thinner': !errors.length > 0,
                }"
                class="form__input -background-transparent -color-input"
                placeholder="Last Name:"
              />
              <label
                class="form__label"
                :class="errors.length > 0 ? 'has-error' : ''"
                >Last Name</label
              >
              <p v-if="errors.length > 0" class="form__error">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
        </div>

        <ValidationProvider v-slot="{ errors }" rules="required|email">
          <div class="form__field">
            <input
              id="email"
              v-model="formData.email"
              :class="{
                '-error-field': errors.length > 0,
                '-border-thinner': !errors.length > 0,
              }"
              class="form__input -background-transparent -color-input"
              placeholder="Email"
            />
            <label
              class="form__label"
              :class="errors.length > 0 ? 'has-error' : ''"
              >Email</label
            >
            <p v-if="errors.length > 0" class="form__error">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required|phone">
          <div class="form__field">
            <vue-tel-input
              id="phone"
              v-model="formData.phone"
              placeholder="Phone:"
              :class="{
                '-error-field': errors.length > 0 && touched,
                '-border-thinner': !(errors.length > 0 && touched),
              }"
              class="form__input -background-transparent -color-input"
              @focus="touched = true"
              @country-changed="countryChanged"
            />

            <p v-if="errors.length > 0 && touched" class="form__error">
              {{ errors[0] }}
            </p>
          </div>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" rules="required">
          <div class="form__field -textarea-margin">
            <textarea
              id="message"
              v-model="formData.message"
              :class="{
                '-error-field': errors.length > 0,
                '-border-thinner': !errors.length > 0,
              }"
              class="form__textarea -background-transparent -color-input"
              placeholder="Please provide more context or details about the project and specify your preferred time slots for phone calls, as we will reach out to you via telephone."
            />
            <p v-if="errors.length > 0" class="form__error">
              {{ errors[0] }}
            </p>
          </div>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required|numeric">
          <div class="form__field -last-input">
            <input
              id="Budget"
              v-model="formData.budget"
              :class="{
                '-error-field': errors.length > 0,
                '-border-thinner': !errors.length > 0,
              }"
              class="form__input -background-transparent -color-input"
              placeholder="Budget(EURO):"
              type="number"
            />
            <label
              :class="errors.length > 0 ? 'has-error' : ''"
              class="form__label"
              >Budget (EURO):</label
            >
            <p v-if="errors.length > 0" class="form__error">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <p v-if="requestError" class="form__error-message">
          There was an error submitting your request. Please try again later.
        </p>
        <button
          type="submit"
          name="submit"
          class="button"
          :class="{
            'is-disabled': invalid === true,
            'is-loading': loading === true,
          }"
          :disabled="invalid"
          @click="$gtag.event('cta', 'click', 'Contact form homepage')"
        >
          <span v-if="!loading" class="button__inner">Get in touch</span>
          <div v-else class="spinner button__inner">
            <LoadingSpinner />
          </div>
        </button>
      </form>
    </ValidationObserver>
    <p v-if="requestError && sending" class="form__error">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  regex,
  numeric,
  alpha_spaces,
} from 'vee-validate/dist/rules'
import LoadingSpinner from './LoadingSpinner.vue'

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Please enter a valid name',
})
extend('required', required)
extend('email', email)
extend('regex', regex)
extend('numeric', numeric)
extend('phone', {
  validate: (value) => {
    const phoneRegex =
      /^(?:\+?(\d{1,4}))?[-.\\\/\s]?(\d{2,4})[-.\\\/\s]?(\d{2,4})[-.\\\/\s]?(\d{2,4})$/
    return phoneRegex.test(value)
  },
  message: 'Please enter a valid phone number',
})

export default {
  name: 'BookForm',
  components: { ValidationObserver, ValidationProvider, LoadingSpinner },
  data() {
    return {
      requestError: false,
      sending: false,
      touched: false,
      validationErrors: {},
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        budget: null,
      },
      loading: false,
      error: 'Your message could not be sent!',
    }
  },
  methods: {
    countryChanged(e) {
      this.formData.phone = '+' + e.dialCode
    },
    async submitForm() {
      this.loading = true
      let antispamVal = this.$refs.antispam.value
      if (antispamVal === '') {
        const { first_name, last_name, email, phone, message, budget } =
          this.formData
        const dataToSend = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          message: message,
          budget: parseInt(budget),
        }
        this.sending = true
        try {
          await axios
            .post(process.env.ENDPOINT_LAMBDA, JSON.stringify(dataToSend), {
              headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
              if (res.data.statusCode === 200) {
                this.requestError = false
                this.$router.push({
                  path: '/thank-you',
                  query: {
                    message:
                      'Your submission has been received. We will be in touch with you shortly via phone call!',
                  },
                })
                this.loading = false
              } else {
                this.requestError = true
              }
            })
        } catch (error) {
          this.requestError = true
          this.loading = false
        } finally {
          this.sending = false
          this.loading = false
        }
      } else {
        this.requestError = true
      }
    },
  },
}
</script>
