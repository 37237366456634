<template>
  <LazyBackgroundWrapper
    background-path="photo"
    background-class="banner"
    placeholder="true"
  />
</template>

<script>
export default {
  name: 'Banner',
}
</script>
