<template>
  <div class="partners__item partner">
    <div class="partner__logo">
      <img
        :src="image"
        :alt="alt"
        :width="width"
        :height="height"
        data-not-lazy="true"
      />
    </div>
    <h4 class="partner__name">
      {{ text }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'Partner',
  props: {
    alt: {
      type: String,
      default: 'Awesome alt',
    },
    text: {
      type: String,
      default: 'Awesome partner',
    },
    image: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
      required: true,
    },
    height: {
      type: Number,
      default: 0,
      required: true,
    },
  },
}
</script>
