<template>
  <div id="portfolio" class="section-wrapper -type-portfolio">
    <frame :parallax-speed="-2" />
    <div class="section--dark">
      <article class="portfolio">
        <div class="portfolio__wrapper">
          <h2 class="heading h1 h-align-center">
            Online <strong>portfolio</strong>
          </h2>
          <div class="portfolio__items">
            <portfolio-item
              v-for="(item, index) in items"
              :key="index"
              :name="item.name"
              :image-name="item.imageName"
              :url="item.url"
              :description="item.description"
              :type="item.type"
            />
          </div>
        </div>

        <partners />

        <div class="h-align-center">
          <a
            v-smooth-scroll
            href="#contact"
            class="button"
            @click="$gtag.event('cta', 'click', 'Let\'s Talk Partners')"
          >
            <span class="button__inner">Contact Us</span>
          </a>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import Partners from './Partners'
import PortfolioItem from './PortfolioItem'

export default {
  name: 'Portfolio',
  components: {
    partners: Partners,
    'portfolio-item': PortfolioItem,
    frame: () =>
      import(
        /* webpackMode: "lazy", webpackChunkName: "frame" */ './Frame'
      ).then((module) => module.default),
  },
  data() {
    return {
      items: [
        {
          name: 'LuxGive',
          imageName: 'luxgive',
          url: '/case-studies#luxgive',
          description:
            'React with TypeScript, Next.js and Netlify powered website for a novel travel startup.',
          type: '',
        },
        {
          name: 'Nick Marks',
          imageName: 'nic-marks',
          url: '/case-studies#nic-marks',
          description:
            'Branding, website design, and wordpress development for the bright mind behind the Happy Planet Index.',
          type: '',
        },
        {
          name: 'Silver Fern',
          imageName: 'silver-fern',
          url: '/case-studies#silver-fern',
          description:
            'React development with Next.js and TypeScript for a leading greenhouse IOT startup.',
          type: '',
        },
        {
          name: 'UN Global Compact',
          imageName: 'un-global-compact',
          url: '/case-studies#un-global-compact',
          description:
            'Modern frontend development using Elm for United Nations Global Compact platform.',
          type: '',
        },
        {
          name: 'Trafalgar Releasing',
          imageName: 'trafalgar-releasing',
          url: '/case-studies#trafalgar-releasing',
          description:
            'Custom WordPress development and AWS infrastructure deployment for a premium international entertainment business.',
          type: '-type-tall',
        },
        {
          name: 'Migro.ro',
          imageName: 'migro',
          url: '/case-studies#migro',
          description:
            'Branding, frontend and WordPress development, for an emerging leader in the tax return field.',
          type: '-type-tall',
        },
        {
          name: 'County Supplies',
          imageName: 'county-supplies',
          url: '/case-studies#county-supplies',
          description:
            'Design, frontend, and WordPress development for the top supplier of fresh goods in the London area.',
          type: '',
        },
      ],
    }
  },
}
</script>
